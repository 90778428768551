<template>
  <v-data-table
    :footer-props="{
      'items-per-page-text': 'Filas por página',
                    'items-per-page-options': [5,10,15]
      
    }"
    :headers="headers"
    :items="dataTable"
    :items-per-page="15"
    :search="search"
    item-key="id"
  ></v-data-table>
</template>

<script>
import { listCases } from "../../../../services/graphicReports/womanService";

export default {
  props: ["queryStats"],
  data() {
    return {
      municipalities: [],
      territories: [],
      sidewalks: [],
      dataTable: [],
      search: "",
      headers: [
        {
          text: "ID.",
          align: "start",
          sortable: true,
          value: "event_id",
        },
        { text: "FECHA", value: "event_date" },
        { text: "TIPOLOGÍA", value: "event_types_name" },
        { text: "MUNICIPIO", value: "municipality_description" },
        { text: "ZONA", value: "zone_description" },
        { text: "TERRITORIO", value: "territory_description" },
        { text: "VEREDA", value: "sidewalk_description" },
        {
          text: "NO. AFECTADAS",
          align: "center",
          value: "affected_people__total",
        },
        { text: "ESTADO", value: "states_name" },
        { text: "COORDINADOR", value: "user_name" },
      ],
    };
  },
  methods: {},
  async beforeMount() {
    const { data } = await listCases(this.queryStats);
    this.dataTable = data;
  },
};
</script>

<style scoped></style>
