import baseService from "../baseService";
const resource = "/rest/v1";

export function caseByType(data) {
  return baseService.post(
    `${resource}/graphic_reports/woman/cases-by-type`,
    data
  );
}

export function caseReported(data) {
  return baseService.post(
    `${resource}/graphic_reports/woman/cases-reported`,
    data
  );
}

export function casesByMunicipalitiesMaps(data) {
  return baseService.post(
    `${resource}/graphic_reports/woman/cases-by-municipalities-maps`,
    data
  );
}

export function listCases(data) {
  return baseService.post(`${resource}/graphic_reports/woman/list-cases`, data);
}

export async function listCasesDonwload(data) {
  return baseService.post(
    `${resource}/graphic_reports/woman/list-cases-download`,
    data,
    {
      responseType: "blob",
    }
  );
}

export function parameterization() {
  return baseService.get(`${resource}/graphic_reports/woman/parameterization`);
}
