import { render, staticRenderFns } from "./CasesReported.vue?vue&type=template&id=143fcf70&scoped=true&"
import script from "./CasesReported.vue?vue&type=script&lang=js&"
export * from "./CasesReported.vue?vue&type=script&lang=js&"
import style0 from "./CasesReported.vue?vue&type=style&index=0&id=143fcf70&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "143fcf70",
  null
  
)

export default component.exports