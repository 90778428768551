<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <h3>REPORTES</h3>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item> Reportes </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>

    <template>
      <v-card class="overflow-hidden mt-2">
        <v-toolbar flat color="white">
          <v-toolbar-title class="font-weight-bold" color="grey darken-3">
            GENERAR REPORTE
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card-text>
          <v-row class="mx-0">
            <v-row>
              <v-col cols="12" sm="12" lg="3" md="3">
                <span class="green--text">Tipología de eventos</span>

                <div class="container-filter">
                  <v-icon color="grey">mdi-format-list-bulleted</v-icon>
                  <v-autocomplete
                    :items="event_types"
                    item-text="name"
                    item-value="id"
                    single
                    label="Tipo de eventos"
                    v-model="filters.event_type_id"
                    @change="changeEventTypes"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="12" sm="12" lg="3" md="3">
                <span class="green--text"
                  >Rango fecha de consulta - Inicio</span
                >

                <v-menu
                  ref="minDatePicker"
                  v-model="minDatePicker"
                  :close-on-content-click="false"
                  :return-value.sync="filters.min_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filters.min_date"
                      label="Rango de fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="handleClearMinDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.min_date"
                    no-title
                    scrollable
                    :max="filters.max_date"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="minDatePicker = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.minDatePicker.save(filters.min_date)"
                    >
                      Aplicar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" lg="3" md="3">
                <span class="green--text">Rango fecha de consulta - Fin</span>

                <v-menu
                  ref="maxDatePicker"
                  v-model="maxDatePicker"
                  :close-on-content-click="false"
                  :return-value.sync="filters.max_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filters.max_date"
                      label="Rango de fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="handleClearMaxDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.max_date"
                    no-title
                    scrollable
                    :min="filters.min_date"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="maxDatePicker = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.maxDatePicker.save(filters.max_date)"
                    >
                      Aplicar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" lg="12" md="12">
              <span class="green--text">Filtrar por</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" lg="3" md="3">
              <div class="container-filter">
                <v-icon color="grey">mdi-map-marker-outline</v-icon>
                <v-autocomplete
                  :items="departaments"
                  item-text="descripcion"
                  item-value="id"
                  single
                  label="Departamento"
                  v-model="filters.departament_id"
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col cols="12" sm="12" lg="2" md="2">
              <div class="container-filter">
                <v-icon color="grey">mdi-map-marker-outline</v-icon>
                <v-autocomplete
                  :items="zones"
                  item-text="descripcion"
                  item-value="id"
                  single
                  label="Zona"
                  v-model="filters.zone_id"
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col cols="12" sm="12" lg="" md="2">
              <div class="container-filter">
                <v-icon color="grey">mdi-map-marker-outline</v-icon>
                <v-autocomplete
                  :items="municipalities"
                  item-text="descripcion"
                  item-value="id"
                  single
                  label="Municipio"
                  v-model="filters.municipality_id"
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col cols="12" sm="12" lg="2" md="2">
              <div class="container-filter">
                <v-icon color="grey">mdi-map-marker-outline</v-icon>
                <v-autocomplete
                  :items="territories"
                  item-text="descripcion"
                  item-value="id"
                  single
                  label="Territorio"
                  v-model="filters.territory_id"
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col cols="12" sm="12" lg="3" md="3">
              <div class="container-filter">
                <v-icon color="grey">mdi-map-marker-outline</v-icon>
                <v-autocomplete
                  :items="sidewalks"
                  item-text="descripcion"
                  item-value="id"
                  single
                  label="Comunidad o vereda"
                  v-model="filters.sidewalk_id"
                ></v-autocomplete>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-row class="mx-0">
            <v-row>
              <v-col
                cols="4"
                sm="4"
                lg="4"
                md="4"
                offset-md="4"
                class="d-flex flex-column justify-center"
              >
                <div class="d-flex justify-center" style="">
                  <v-btn
                    rounded
                    color="primary"
                    dark
                    class="mb-5"
                    @click="generateReports()"
                  >
                    GENERAR REPORTE <v-icon>mdi-chart-line</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
      </v-card>
    </template>

    <template v-if="showView">
      <v-card class="overflow-hidden mt-2">
        <v-toolbar flat color="white">
          <v-toolbar-title class="font-weight-bold" color="grey darken-3">
            REPORTE
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
      </v-card>

      <v-card-text>
        <v-row class="mt-2">
          <v-col md="6" lg="6" sm="12" xs="12">
            <v-card>
              <v-card-text class="">
                <h3
                  class="title blue-grey--text text--darken-2 font-weight-regular"
                >
                  CASOS POR {{ currentEventType.name || 'TIPOLOGÍA' }}
                </h3>
                <h6 class="subtitle-2 font-weight-light"></h6>
                <div class="">
                  <cases-by-type :queryStats="filters"></cases-by-type>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="6" lg="6" sm="12" xs="12">
            <v-card>
              <v-card-text class="">
                <h3
                  class="title blue-grey--text text--darken-2 font-weight-regular"
                >
                  CASOS REPORTADOS
                </h3>
                <h6 class="subtitle-2 font-weight-light"></h6>
                <div class="" max-height="100">
                  <cases-reported :queryStats="filters"></cases-reported>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col md="12" lg="12" sm="12" xs="12">
            <v-card>
              <v-card-text class="">
                <h3
                  class="title blue-grey--text text--darken-2 font-weight-regular"
                >
                  CASOS POR MUNICIPIOS
                </h3>
                <h6 class="subtitle-2 font-weight-light"></h6>
                <div class="" max-height="100">
                  <cases-by-municipalities-maps
                    :queryStats="filters"
                  ></cases-by-municipalities-maps>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col md="12" lg="12" sm="12" xs="12">
            <v-card>
              <v-card-text class="">
                <div class="d-flex align-center justify-md-space-between">
                  <h3
                    class="title blue-grey--text text--darken-2 font-weight-regular"
                  >
                    LISTADO DE CASOS
                  </h3>
                  <v-btn
                    rounded
                    color="primary"
                    dark
                    class="mb-5"
                    @click="donwloadListCase()"
                  >
                    EXPORTAR A EXCEL <v-icon>mdi-chart-line</v-icon>
                  </v-btn>
                </div>
                <h6 class="subtitle-2 font-weight-light"></h6>
                <div class="" max-height="100">
                  <list-cases :queryStats="filters"></list-cases>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
  </v-container>
</template>

<script>
import sweetalert from "sweetalert2";

import CasesByType from "./reports/CasesByType.vue";
import CasesReported from "./reports/CasesReported.vue";
import CasesByMunicipalitiesMaps from "./reports/CasesByMunicipalitiesMaps.vue";
import ListCases from "./reports/ListCases.vue";

import {
  listCasesDonwload,
  parameterization,
} from "../../../services/graphicReports/womanService";

export default {
  components: {
    CasesByType,
    CasesReported,
    CasesByMunicipalitiesMaps,
    ListCases,
  },
  name: "graphicReports.vue",
  data() {
    return {
      currentEventType: {},
      event_types: [],
      departaments: [],
      zones: [],
      municipalities: [],
      territories: [],
      sidewalks: [],
      states: [],
      filters: {
        min_date: "",
        max_date: "",
        event_type_id: 0,
        departament_id: 0,
        zone_id: 0,
        municipality_id: 0,
        territory_id: 0,
        sidewalk_id: 0,
        state_id: 0,
      },
      showView: false,
      minDatePicker: false,
      maxDatePicker: false,
    };
  },
  methods: {
    async fillData() {
      this.showLoader();
      const { data } = await parameterization();
      this.event_types = data.event_types || [];
      this.departaments = data.departaments || [];
      this.zones = data.zones || [];
      this.municipalities = data.municipalities || [];
      this.territories = data.territories || [];
      this.sidewalks = data.sidewalks || [];
      this.states = data.states || [];
      this.hideLoader();
    },
    handleClearMinDate() {
      this.filters.min_date = '';
      this.handleReloadReports(); 
    },
    handleClearMaxDate() {
      this.filters.max_date = '';
      this.handleReloadReports(); 
    },
    changeEventTypes(event) {
      this.currentEventType = this.event_types.find(
        (item) => item.id === event
      );
      const auxCurrentEventType = this.currentEventType;
      this.currentEventType = {};

      this.$nextTick(() => {
        this.currentEventType = auxCurrentEventType;
      });
    },
    async generateReports() {
      this.showView = false;
      this.$nextTick(() => {
        this.showView = true;
      });
    },
    async donwloadListCase() {
      const { data = null } = await listCasesDonwload(this.filters);
      if (!data) return;

      var url = window.URL || window.webkitURL;
      const link = url.createObjectURL(data);
      var a = document.createElement("a");
      a.setAttribute("download", "Lista de casos - Observatorio VBG Mujeres.xlsx");
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  async created() {
    try {
      await this.fillData();
    } catch (err) {
      this.hideLoader();
      sweetalert.fire(
        "Ups!",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
};
</script>

<style scoped>
.container-filter {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
